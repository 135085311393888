import React from 'react'
import Layout from '@components/Layout'
import { Seo } from '@components/Seo'
import { graphql } from 'gatsby'
import PageTitle from '@components/PageTitle'
import { Article, Content } from '@global'
import { Newsdate } from './styles'

const NewsTemplate = ({ data }) => {
  const news = data.datoCmsNews

  const seotitle = news.title + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  return (
    <Layout>
      <Article>
        <Seo title={seotitle} />
        <PageTitle title={news.title} />
        <Newsdate>{news.date}</Newsdate>
        <Content
          dangerouslySetInnerHTML={{
            __html: news.contentNode.childMarkdownRemark.html,
          }}
        />
      </Article>
    </Layout>
  )
}

export const newsListingQueryGraph = graphql`
  query newsv2ListingQuery($slug: String!) {
    datoCmsNews(slug: { eq: $slug }) {
      id
      title
      date
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default NewsTemplate
